<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2021-03-08 17:26:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="product">
    <section class="pro-list">
      <div class="nav-left">
        <!-- 搜索box -->
        <!-- <div class="search-box">
          <div class="icon-box">
            <i class="el-icon-search"></i>
          </div>
          <el-input v-model="searchInput" placeholder="产品搜索"></el-input>
        </div> -->
        <!-- nav list -->
        <ul class="nav-filter">
          <li v-for="(item,index) in navList" :key="item.id" :class="chooseValue.id===index?'this-nav active':'this-nav'" @click="chooseFilter(item)">
            <span>{{ item.title }}</span>
            <i class="el-icon-arrow-right" />
          </li>
        </ul>
      </div>
      <div class="pro-left">
        <ul class="pro-block">
          <li
            v-for="(item,index) in proList"
            :key="index"
            @click="linkProMall(item)"
            @mouseenter="mouseoverPro(item)"
            @mouseleave="mouseleavePro(item)"
          >
            <img :src="item.imgUrl">
            <h3>{{ item.title }}</h3>
            <h5>{{ item.colorTitle }}</h5>
          </li>
        </ul>
        <el-pagination
          class="pagination"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          background
          layout=" prev, pager, next"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Product',
  data() {
    return {
      searchInput: '',
      navList: [{
        title: '智能家教学习桌',
        id: 0
      }, {
        title: '青少年可升降矫姿椅',
        id: 1
      }],
      chooseValue: {
        title: '智能家教学习桌',
        id: 0
      },
      proList: [{
        imgUrl: require('../../assets/images/product/pro1-1-1.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-1-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1792',
        title: '课桌先生-卓越系列',
        colorTitle: '粉色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-2.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-2-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1792',
        title: '课桌先生-卓越系列',
        colorTitle: '蓝色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-3.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-3-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1792',
        title: '课桌先生-卓越系列',
        colorTitle: '灰色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-4.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-4-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1790',
        title: '课桌先生-创想系列',
        colorTitle: '粉色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-5.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-5-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1790',
        title: '课桌先生-创想系列',
        colorTitle: '蓝色'
      }],
      // 粉色桌子
      proList1: [{
        imgUrl: require('../../assets/images/product/pro1-1-1.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-1-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1792',
        title: '课桌先生-卓越系列',
        colorTitle: '粉色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-2.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-2-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1792',
        title: '课桌先生-卓越系列',
        colorTitle: '蓝色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-3.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-3-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1792',
        title: '课桌先生-卓越系列',
        colorTitle: '灰色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-4.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-4-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1790',
        title: '课桌先生-创想系列',
        colorTitle: '粉色'
      }, {
        imgUrl: require('../../assets/images/product/pro1-1-5.png'),
        imgUrlB: require('../../assets/images/product/pro1-1-5-B.png'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=1790',
        title: '课桌先生-创想系列',
        colorTitle: '蓝色'
      }],
      // 椅子
      proList4: [{
        imgUrlB: require('../../assets/images/product/pro2-1.jpg'),
        imgUrl: require('../../assets/images/product/pro2-1-B.jpg'),
        proMallUrl: 'https://www.uaregood.net/goods.php?id=2095',
        changeImg: '',
        title: '青少年可升降矫姿椅',
        colorTitle: 'KROZ-C802'
      }, {
        imgUrlB: require('../../assets/images/product/pro2-2.jpg'),
        imgUrl: require('../../assets/images/product/pro2-2-B.jpg'),
        proMallUrl: 'https://www.uaregood.net/goods.php?id=2095',
        changeImg: '',
        title: '青少年可升降矫姿椅',
        colorTitle: 'KROZ-C802'
      }, {
        imgUrlB: require('../../assets/images/product/pro2-3.jpg'),
        imgUrl: require('../../assets/images/product/pro2-3-B.jpg'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=2080',
        title: '青少年可升降矫姿椅',
        colorTitle: 'KROZ-C801'
      }, {
        imgUrlB: require('../../assets/images/product/pro2-4.jpg'),
        imgUrl: require('../../assets/images/product/pro2-4-B.jpg'),
        changeImg: '',
        proMallUrl: 'https://www.uaregood.net/goods.php?id=2080',
        title: '青少年可升降矫姿椅',
        colorTitle: 'KROZ-C801'
      }],
      // 分页
      pageNum: 1,
      pageSize: 5,
      total: 5
    };
  },
  methods: {
    mouseoverPro(item) {
      item.changeImg = item.imgUrl;
      item.imgUrl = item.imgUrlB;
    },
    mouseleavePro(item) {
      item.imgUrl = item.changeImg;
      item.changeImg = '';
    },
    linkProMall(item) {
      if (item.proMallUrl) {
        window.open(item.proMallUrl, '_blank');
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pageNum = val;
      if (val === 1) {
        this.proList = this.proList1;
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (val === 1) {
        this.proList = this.proList1;
      }
    },
    // 切换选择桌子和椅子
    chooseFilter(item) {
      this.chooseValue = item;
      if (item.id) {
        this.total = 4;
        this.proList = this.proList4;
      } else {
        this.total = 5;
        this.proList = this.proList1;
      }
    }
  }
};
</script>

<style  scope lang="scss">
@media screen and (min-width: 1400px) {
  .product{
    width: 100%;
    height: auto;
    background: #F5F5F5;
    .pro-list{
      width: 1400px;
      height: auto;
      margin: 0 auto;
      padding: 64px 0 122px;
      display: flex;
      justify-content: space-between;
      .nav-left{
        width: 380px;
        height: 337px;
        background: #fff;
        padding: 44px 30px;
        border-radius: 10px;
        // .search-box{
        //   display: flex;
        //   align-items: center;
        //   width: 324px;
        //   height: 52px;
        //   background: #FFFFFF;
        //   border: 1px solid #8A8684;
        //   border-radius: 10px;
        //   .icon-box{
        //     padding: 4px 25px 0;
        //     height: 28px;
        //     border-right: 1px solid #8A8684;
        //   }
        //   .el-icon-search{
        //     color: #313131;
        //     font-size: 20px;
        //   }
        //   .el-input__inner{
        //     border: none!important;
        //     font-size: 18px;
        //     color: #535353;
        //   }
        // }
        .nav-filter{
          width: 100%;
          margin-top: 16px;
          .this-nav{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 30px 0;
            border-bottom: 1px solid #8A8684;
            cursor: pointer;
            span{
              color: #535353;
            }
            i{
              color: #8A8684;
            }
            &:hover{
              span{
                color: #41B1E6;
              }
              i{
                color: #41B1E6;
              }
            }
          }
          .active{
            span{
              color: #41B1E6;
            }
            i{
              color: #41B1E6;
            }
          }
        }
      }
      .pro-left{
        flex: 1;
        margin-left: 56px;
        .pro-block{
          display: flex;
          flex-wrap: wrap;
          li{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 299px;
            background: #FFFFFF;
            border-radius: 10px;
            margin-right: 30px;
            margin-bottom: 24px;
            overflow: hidden;
            cursor: pointer;
            img{
              width: 299px;
              height: 299px;
            }
            h3{
              font-size: 22px;
              font-weight: 500;
              line-height: 25px;
              color: #535353;
              margin-top: 45px;
            }
            h5{
              font-size: 18px;
              line-height: 25px;
              color: #7B7F87;
              font-weight: 100;
              margin: 15px 0 54px;
            }
            &:nth-of-type(3n){
              margin-right: 0;
            }
            &:hover{
              box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.16);
              h3{
                color: #41B1E6;
              }
              img{
                // transform: scale(1.1);  /*以y轴为中心旋转*/
              }
            }
          }
        }
        .el-pagination.is-background .el-pager li:not(.disabled):hover{
          color: #41B1E6;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
          background-color: #41B1E6!important;
          color: #fff;
        }

        .pagination{
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .product{
    width: 100%;
    height: auto;
    background: #F5F5F5;
    .pro-list{
      width: 100%;
      height: auto;
      margin: 0 auto;
      padding: .53rem;
      .nav-left{
        width: 100%;
        height: auto;
        background: #fff;
        padding: .693rem .32rem;
        border-radius: .267rem;
        // .search-box{
        //   display: flex;
        //   align-items: center;
        //   width: 324px;
        //   height: 52px;
        //   background: #FFFFFF;
        //   border: 1px solid #8A8684;
        //   border-radius: 10px;
        //   .icon-box{
        //     padding: 4px 25px 0;
        //     height: 28px;
        //     border-right: 1px solid #8A8684;
        //   }
        //   .el-icon-search{
        //     color: #313131;
        //     font-size: 20px;
        //   }
        //   .el-input__inner{
        //     border: none!important;
        //     font-size: 18px;
        //     color: #535353;
        //   }
        // }
        .nav-filter{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .this-nav{
            font-size: .48rem;
            cursor: pointer;
            span{
              color: #8A8684;
            }
            i{
              display: none;
            }
          }
          .active{
            span{
              color: #41B1E6;
            }
            i{
              color: #41B1E6;
            }
          }
        }
      }
      .pro-left{
        width: 100%;
        margin-top: .53rem;
        .pro-block{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 4.293rem;
            background: #FFFFFF;
            border-radius: .267rem;
            margin-bottom: .32rem;
            overflow: hidden;
            cursor: pointer;
            img{
              width: 100%;
              height: auto;
            }
            h3{
              font-size: .373rem;
              font-weight: 500;
              color: #535353;
              margin-top: .587rem;
            }
            h5{
              font-size: .32rem;
              color: #7B7F87;
              font-weight: 100;
              margin: .133rem 0 .8rem;
            }
            &:nth-of-type(2n){
              margin-right: 0;
            }
          }
        }
        .el-pagination.is-background .el-pager li:not(.disabled):hover{
          color: #41B1E6;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
          background-color: #41B1E6!important;
          color: #fff;
        }

        .pagination{
          text-align: center;
          margin: .53rem  0;
        }
      }
    }
  }
}

</style>
